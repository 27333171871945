import chipStatusColor from './chipStatusColor'
import formatJuridicParamsToApi from './formatJuridicParamsToApi'
import formatPersonalParamsToApi from './formatPersonalParamsToApi'
import formatSupplierParamsToApi from './formatSupplierParamsToApi'
import formatTemplateParamsToApi from './formatTemplateParamsToApi'
import nameWithDocument from './nameWithDocument'
import isSupplier from './isSupplier'
import isTemplate from './isTemplate'
import blockUpdate from './blockUpdate'
import typeAndSearch from './typeAndSearch'
import companyKindLabel from './companyKindLabel'

const companies = {
  blockUpdate,
  chipStatusColor,
  companyKindLabel,
  formatJuridicParamsToApi,
  formatPersonalParamsToApi,
  formatSupplierParamsToApi,
  formatTemplateParamsToApi,
  isSupplier,
  isTemplate,
  nameWithDocument,
  typeAndSearch,
}

export default companies
