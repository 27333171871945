import { useState } from 'react'

import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  Permitted,
  SelectCompanyType,
} from 'components'
import { CompaniesTable, AccessButton } from './components'
import {
  Button,
  Tabs,
  Tab,
  Card,
  Box,
  Grid,
  useMediaQuery,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'
import { Plus as PlusIcon } from 'react-feather'

import constants from 'constants/index'
import helpers from 'helpers'

import { useCompaniesCache } from 'hooks/caches'

const CompaniesMain = () => {
  const classes = useStyles()
  const filter = useFilter()
  const theme = useTheme()
  const companyCache = useCompaniesCache()

  const [currentTab, setCurrentTab] = useState(
    constants.companies.STATUSES.ALL_SORTS,
  )
  const [openModal, setOpenModal] = useState(false)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const defaultOrder =
    currentTab === constants.companies.TABS[1].value
      ? constants.orderFilter.NEWLY_UPDATED
      : constants.orderFilter.NEWLY_CREATED

  const formattedDefaultOrder = helpers.functions.formatOrderValue(defaultOrder)

  const paramsFilter = {
    ...filter.filters,
    order: filter.filters?.order ?? formattedDefaultOrder,
    status:
      currentTab === constants.companies.STATUSES.ALL_SORTS ? {} : currentTab,
  }

  const companiesRequest = companyCache.useList({
    ...paramsFilter,
    unscoped: true,
    perPage,
    page,
  })

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
  }
  const refresh = () => {
    companyCache.useUpdateCache()
  }

  return (
    <Page title="Empresas">
      <LoadingFeedback open={companiesRequest.isFetching} />
      <Container maxWidth={false}>
        <ContentHeader
          menu="Empresas"
          title="Listagem"
          subtitle="Exibição das empresas"
        >
          <Permitted tag={constants.permissions.COMPANIES.LIST_COMPANY_STEPS}>
            <Box mr={1}>
              <AccessButton paramsFilter={paramsFilter} />
            </Box>
          </Permitted>
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Box my={2}>
          <Tabs
            scrollButtons="on"
            onChange={handleChangeTabs}
            textColor="primary"
            indicatorColor="primary"
            value={currentTab || constants.companies.STATUSES.ALL_SORTS}
            className={classes.borderBottom}
            variant="scrollable"
          >
            {constants.companies.TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={<Box>{tab.label}</Box>}
              />
            ))}
          </Tabs>
          <Card>
            <Box
              display="flex"
              width="100%"
              p={0}
              className={classes.borderBottom}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">Empresas</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Permitted tag={constants.permissions.COMPANIES.CREATE}>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PlusIcon />}
                        onClick={() => setOpenModal(true)}
                      >
                        Criar
                      </Button>
                    </Permitted>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <SelectCompanyType
              open={openModal}
              onClose={() => setOpenModal(false)}
              items={constants.companies.SELECT_TYPE_COMPANIES_CREATE}
              testOrTemplate={true}
            />
            <CompaniesTable
              companies={companiesRequest?.data?.companies}
              refresh={refresh}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={companiesRequest?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
          <Filters filter={filter} defaultOrder={defaultOrder}>
            <input textfieldinput="true" label="Identificador" name="id" />
            <input textfieldinput="true" label="Nome da empresa" name="name" />
            <input textfieldinput="true" label="Documento" name="document" />
            <select
              textfieldinput="true"
              label="Tipo de empresa"
              name="company_type"
            >
              <option value=""></option>
              {constants.companies.COMPANY_MULTIPLE_FILTER_OPTIONS.map(
                (type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ),
              )}
            </select>
            <select
              textfieldinput="true"
              label="Empresas parceiras"
              name="partner"
            >
              <option value=""></option>
              {constants.companies.DEMO_PARTNER_MULTIPLE_FILTER_OPTIONS.map(
                (partner) => (
                  <option key={partner.id} value={partner.id}>
                    {partner.name}
                  </option>
                ),
              )}
            </select>
            <input
              datepickerinput="true"
              label="Data de criação inicial"
              name="createdAtGt"
              data-target="createdAtLt"
            />
            <input
              datepickerinput="true"
              label="Data de criação final"
              name="createdAtLt"
              data-target="createdAtGt"
            />
          </Filters>
        </Box>
      </Container>
    </Page>
  )
}

export default CompaniesMain
