import auth from './auth'
import collaboratorProfiles from './collaboratorProfiles'
import companies from './companies'
import companyAudits from './companyAudits'
import companySizes from './companySizes'
import companySuppliers from './companySuppliers'
import coupons from './coupons'
import churns from './churns'
import dataCollectedOptions from './dataCollectedOptions'
import dataCollecteds from './dataCollecteds'
import dataLifeCycles from './dataLifeCycles'
import dataProcesses from './dataProcesses'
import dataTreatmentAgents from './dataTreatmentAgents'
import dataTreatments from './dataTreatments'
import departments from './departments'
import documentationLinks from './documentationLinks'
import documents from './documents'
import documentsTemplate from './documentsTemplate'
import economicActivities from './economicActivities'
import faqsDocumentations from './faqsDocumentations'
import faqsDocumentationsCategories from './faqsDocumentationsCategories'
import groups from './groups'
import incidents from './incidents'
import internationalTransfer from './internationalTransfers'
import lastUpdates from './lastUpdates'
import legalFrameworks from './legalFrameworks'
import moodleLotus from './moodleLotus'
import nonComplianceReports from './nonComplianceReports'
import observerInvite from './observerInvite'
import outsourcedDpo from './outsourcedDpo'
import plans from './plans'
import preRegistrations from './preRegistrations'
import privacyPolicies from './privacyPolicies'
import questionnaires from './questionnaires'
import questions from './questions'
import segments from './segments'
import sharedSoftwares from './sharedSoftwares'
import subgroups from './subgroups'
import user from './users'
import userCollaborators from './userCollaborators'
import userCompanies from './userCompanies'
import userProfiles from './userProfiles'
import technicalFaqs from './technicalFaqs'
import reports from './reports'
import paymentContract from './paymentContract'
import paymentOrders from './paymentOrders'
import logs from './logs'
import sellers from './sellers'
import billingEmails from './billingEmails'

const dponet = {
  auth,
  collaboratorProfiles,
  companies,
  companyAudits,
  companySizes,
  companySuppliers,
  coupons,
  churns,
  dataCollectedOptions,
  dataCollecteds,
  dataLifeCycles,
  dataProcesses,
  dataTreatmentAgents,
  dataTreatments,
  departments,
  documentationLinks,
  documents,
  documentsTemplate,
  economicActivities,
  faqsDocumentations,
  faqsDocumentationsCategories,
  groups,
  incidents,
  internationalTransfer,
  lastUpdates,
  legalFrameworks,
  moodleLotus,
  nonComplianceReports,
  observerInvite,
  outsourcedDpo,
  plans,
  preRegistrations,
  privacyPolicies,
  questionnaires,
  questions,
  segments,
  sharedSoftwares,
  subgroups,
  user,
  userCollaborators,
  userCompanies,
  userProfiles,
  technicalFaqs,
  reports,
  paymentContract,
  paymentOrders,
  logs,
  sellers,
  billingEmails,
}

export default dponet
